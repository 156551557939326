<template >
  <ion-page>
    <Header type="0" title="扫码装车"> </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>车辆编号</div>
          <div class="right">
            <input
              @input="scan"
              placeholder="请扫描车辆二维码"
              v-model="listQuery.vehicleCode"
            />
          </div>
        </div>
        <div class="item">
          <div class="left"><span class="required">*</span>试制任务单号</div>
          <div class="right">
            {{ listQuery.stageCode }}
          </div>
        </div>

        <div class="item">
          <div class="left"><span class="required">*</span>零件编号</div>
          <div class="right">
            <input
              @input="scanPart"
              placeholder="请扫描零件二维码"
              v-model="listQuery.partCode"
            />
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="!formData.id && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <!-- <entruck-card v-if="idx >= 0" :dataItem="tableList[idx]"></entruck-card> -->
      <div class="app-container" v-if="formData.id">
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ formData.partCode }}</div>
          <div class="title">{{ formData.partNameCN }}</div>
          <div class="rate">{{ formData.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">每车数量</div>
          <div class="item-content">{{ formData.eachNum }}</div>
        </div>
        <div class="item">
          <div class="item-label">扫码数量</div>
          <div class="item-content">
            {{ formData.installNum }}
          </div>
        </div>
      </div>
      <CardLoading v-if="islist" lodingType="1"></CardLoading>
    </ion-content>
    <ion-footer>
      <ion-button expand="block" @click="openModal" color="success"
        >查看已扫描清单</ion-button
      >
      <ion-button expand="block" @click="commit">保存</ion-button>

      <!-- <ion-button v-if="isDrag" expand="block" color="danger">
          <i class="iconfont icon-shanchujieduan"></i>
        </ion-button> -->
    </ion-footer>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import { alertController, modalController, ionAlert } from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import {
  getVehPartList,
  updateInstallNum,
  getVehicleState,
} from "@/api/entrucking/entrucking";
import entruckCard from "./entruckCard.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import scanner from "@/components/cordova/qrScanner.vue";
import SystemUtils from "@/utils/SystemUtils";
import listModal from "./listModal.vue";

export default {
  components: {
    Header,
    uploadImg,
    entruckCard,
    CardLoading,
    ionAlert,
    scanner,
    listModal,
  },
  data() {
    return {
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      idx: -1,
      listQuery: {
        stageId: "",
        vehicleCode: "",
        partCode: "",
        partVersion: "",
        type: 2,
      },
      formData: {},
      showScan: false,
    };
  },
  mounted() {},
  methods: {
    getList() {
      this.islist = true;
      getVehPartList(this.listQuery).then((res) => {
        this.islist = false;
        if (
          res.data.data &&
          res.data.data.isSuccess &&
          res.data.data.resultData.length > 0
        ) {
          var bool = this.tableList.some((e) => {
            return e.id == res.data.data.resultData[0].id;
          });
          if (bool) {
            this.tableList.forEach((element) => {
              if (element.id == this.formData.id) {
                if (element.installNum == element.eachNum) {
                  Utils.presentToastTopWarning("此车已装满该零件", "danger");
                } else {
                  element.installNum =
                    typeof element.installNum == "number"
                      ? ++element.installNum
                      : 1;
                  this.formData = element;
                }
              }
            });
          } else {
            this.formData = res.data.data.resultData[0];
            this.formData.installNum = 1;
            this.tableList.push(this.formData);
          }
        } else {
          this.listQuery.partCode = "";
          this.listQuery.partVersion = "";
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "warning"
          );
        }
      });
    },
    transfer(type) {
      this.showScan = true;
    },
    async openModal(item) {
      var _this = this;
      const modal = await modalController.create({
        component: listModal,
        cssClass: "my-custom-class",
        componentProps: {
          item: _this.tableList,
        },
      });
      modal.present();
    },
    openChooseOrganizationModal() {},
    async commit() {
      const _this = this;
      const user = SystemUtils.loginUser;
      const now = new Date();
      const data = {
        stageId: _this.listQuery.stageId,
        vehicleCode: _this.listQuery.vehicleCode,
        modifierId: user.id,
        modifier: user.name,
        modifyTime:
          now.getFullYear() +
          "-" +
          (now.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          now.getDate().toString().padStart(2, "0") +
          " " +
          now.getHours().toString().padStart(2, "0") +
          ":" +
          now.getMinutes().toString().padStart(2, "0") +
          ":" +
          now.getSeconds().toString().padStart(2, "0"),
        partsItems: _this.tableList,
      };
      console.log(data.modifyTime);
      if (_this.tableList.length == 0) {
        Utils.presentToastTopWarning("无可装车零件", "danger");
        return false;
      }
      updateInstallNum(data).then((res) => {
        if (!res.data.isError) {
          _this.$router.go(-1);
          Utils.presentToastTopWarning("操作成功", "success");
        } else {
          Utils.presentToastTopWarning(res.data.message, "danger");
        }
      });
    },

    test() {
      this.listQuery.vehicleCode =
        '"stageCode":"2021SZ00474","stageId":"2826","vehicleCode":"V1"';
    },
    scan(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.listQuery.vehicleCode;
        if (scanVal.indexOf('"') > -1) {
          _this.listQuery.vehicleCode = "";
          _this.listQuery.partCode = "";
          if (
            scanVal.indexOf("stageId") > -1 &&
            scanVal.indexOf("vehicleCode") > -1
          ) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            getVehicleState({
              stageId: contents.stageId,
              vehicleCode: contents.vehicleCode,
            }).then((res) => {
              if (res.data.data.isSuccess) {
                _this.listQuery.stageId = contents.stageId;
                _this.listQuery.stageCode = contents.stageCode;
                _this.listQuery.vehicleCode = contents.vehicleCode;
                _this.listQuery.partCode = "";
                _this.listQuery.partVersion = "";
                _this.tableList = [];
                Utils.presentToastTopWarning("继续扫描零件二维码", "success");
              } else {
                Utils.presentToastTopWarning("该车已装满", "danger");
              }
            });
          }
        } else if (_this.listQuery.vehicleCode == "") {
          _this.listQuery.partCode = "";
          _this.listQuery.stageCode = "";
        }
      });
    },
    scanPart(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.listQuery.partCode;

        if (scanVal.indexOf('"') > -1) {
          _this.listQuery.partCode = "";
          if (scanVal.indexOf("partCode") > -1) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            if (_this.listQuery.stageId) {
              _this.listQuery.partCode = contents.partCode;
              _this.listQuery.partVersion = contents.partVersion;
              _this.getList();
            } else {
              Utils.presentToastTopWarning("请先扫描车辆二维码", "danger");
            }
          }
        }
      });
    },
    closeScan(contents) {
      this.showScan = false;
      if (contents.stageId && contents.vehicleCode) {
        this.listQuery.stageId = contents.stageId;
        this.listQuery.vehicleCode = contents.vehicleCode;
        this.listQuery.partCode = "";
        this.listQuery.partVersion = "";
        this.tableList = [];
        Utils.presentToastTopWarning("继续扫描零件二维码", "success");
      } else if (contents.partCode) {
        //写到这 扫描零件二维码
        if (this.listQuery.stageId) {
          this.listQuery.partCode = contents.partCode;
          this.listQuery.partVersion = contents.partVersion;
          this.getList();
        } else {
          Utils.presentToastTopWarning("请先扫描车辆二维码", "danger");
        }
      } else {
        Utils.presentToastTopWarning("请先扫描车辆二维码", "danger");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
input {
  border: 0;
  outline: none;
  text-align: right;
}
</style>