<template >
  <Header
    type="0"
    title="扫描清单"
    :isBack="false"
    :btnIconRight="['icon-guanbi']"
    @transfer="transfer"
  >
  </Header>
  <ion-content class="ion-padding" id="ProjectMainProjectList">
    <div class="app-container" v-for="formData in item" :key="formData.id">
      <div
        class="item"
        style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
      >
        <div class="item-label">{{ formData.partCode }}</div>
        <div class="title">{{ formData.partNameCN }}</div>
        <div class="rate">{{ formData.partVersion }}</div>
      </div>
      <div class="item">
        <div class="item-label">每车数量</div>
        <div class="item-content">{{ formData.eachNum }}</div>
      </div>
      <div class="item">
        <div class="item-label">扫码数量</div>
        <div class="item-content">
          {{ formData.installNum }}
        </div>
      </div>
    </div>
  </ion-content>
</template>
<script>
import { defineComponent } from "vue";
import { modalController, menuController, navParames } from "@ionic/vue";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils.ts";
import CardLoading from "@/components/ListCard/CardLoading.vue";
export default defineComponent({
  components: {
    Header,
    CardLoading,
  },
  props: {
    item: Array,
  },
  data() {
    return {};
  },
  methods: {
    transfer() {
      modalController.dismiss();
    },
  },
});
</script>
<style lang="scss" scoped>
.app-container {
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>