import request from "@/utils/request"

// 获取零件总装清单(检查件)
export function getVehPartList(data){
    return request('/codeVeh/getVehPartList.do',"POST",data)
}
// 更新扫码安装数量(检查件)
export function updateInstallNum(data){
    return request('/codeVeh/updateInstallNum.do',"POST",data)
}

//查看是否装车完成
export function getVehicleState(data){
    return request('/codeVeh/getVehicleState.do',"POST",data)
}
